<template>
    <div>   
        <a-spin tip="資料處理中..." :spinning="spinning">
                <a-table
                    :columns="columns"
                    :row-key="record => record.id"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    @change="handleTableChange"
                    bordered
                >
                    <p slot="expandedRowRender" slot-scope="record" style="margin: 0;">
                        <template>
                            <div class="timeline education">
                                <h2 class="timeline-title">訊息清單</h2>
                                <div class="timeline-items">
                                    <div class="timeline-item">
                                        <h6>訊息類別</h6>
                                        <div class="location">{{ convert(record.types) }}</div>
                                    </div>
                                    <div class="timeline-item">
                                        <h6>標題</h6>
                                        <div class="location">{{ record.title }}</div>
                                    </div>
                                    <div class="timeline-item">
                                        <h6>內容</h6>
                                        <div class="location">{{ record.desc }}</div>
                                    </div>
                                    <div class="timeline-item" v-if="record.types=='2' || record.types=='3'">
                                        <h6>連結標題</h6>
                                        <div class="location">{{ record.stxt }}</div>
                                    </div>    
                                    <div class="timeline-item" v-if="record.types=='2' || record.types=='3'">
                                        <h6>連結</h6>
                                        <div class="location">{{ record.surl }}</div>
                                    </div>
                                    <div class="timeline-item" v-if="record.types=='2' || record.types=='3'">
                                        <h6>圖片</h6>
                                        <div class="location">
                                            <b-avatar rounded :src="record.url" size="5rem"></b-avatar>
                                        </div>
                                    </div>                                                                                                    
                                </div>
                            </div>

                        </template>
                    </p>                  
                    <template slot="types" slot-scope="types"> {{ convert(types) }}</template>
                    <template slot="created_at" slot-scope="created_at"> {{ fdateformat(created_at) }}</template>
                </a-table> 
            </a-spin>                 
                 
    </div>
</template>

<script>
import { lineService } from '../../../_services'
import * as moment from "moment/moment";

import {mapState} from 'vuex';

const queryData = params => {
  return lineService.findhistory(params) 
}; 

const columns = [
  {
    title: '接收訊息',
    dataIndex: 'username',
    width: '15%',    
    scopedSlots: { customRender: 'username' },    
  },  
  {
    title: '地址',
    dataIndex: 'address',
    width: '20%',    
    scopedSlots: { customRender: 'address' },    
  },       
  {
    title: '訊息類別',
    dataIndex: 'types',
    width: '15%',    
    scopedSlots: { customRender: 'types' },    
  }, 
  {
    title: '訊息標題',
    dataIndex: 'title',
    width: '35%',
    scopedSlots: { customRender: 'title' },    
  },      
  {
    title: '建立日期',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'created_at' },    
    width: '15%',
  },  

];


export default {
    name: 'BlockHistory',       
    data(){
        return {
            selected:'',            
            tid:'',
            spinning: false, 
            errors:'',
            pagination: {defaultPageSize:4},
            loading: false,            
            data: [],
            columns,
        }
    },
    computed:{
      ...mapState('account',['status','user']),

    },    
    methods :{

        convert(val){
        let rs=''
        val=="1"? rs="文字訊息":""
        val=="2"? rs="圖文訊息":""
        val=="3"? rs="圖片訊息":""
        val=="4"? rs="彈性樣板訊息":""    
        return rs

        },        
        
        fdateformat(val){
            return moment(val).format("YYYY-MM-DD"); 
        }, 

        handleTableChange(pagination, filters, sorter) {
            //console.log(pagination);
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            //console.log(this.pagination);
            this.fetch({
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                uid: this.user.data.user.id,
                ...filters,
            });
        }, 

        fetch(params = {}) {
        this.loading = true;
        queryData({
            ...params,
        }).then(({ data }) => {
            const pagination = { ...this.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.info.totail;
            //pagination.total = 200;
            this.loading = false;
            this.data = data.results;
            this.pagination = pagination; 
        });
        },          
          
        reload(rs){
            if(rs==null){
                this.fetch({results:4,page:1,pageSize: 4 ,uid: this.user.data.user.id});
            }else{
                this.fetch(rs);
            }
        },       

    }, 
    mounted() {
        this.reload()
    },  

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}

.card-3 {
    padding:20px;
    border-radius: 6px;
    text-align: left;
    border-width:0px;
    margin: 25px auto;
    border-style:solid;
    border-color:#e0dedc;
	transition: all .3s ease;
}

.ddic {
  border-radius: 10px;
  box-shadow: 1px 1px 15px -5px rgb(206, 204, 204);
  transition: all 0.3s ease;
}

.hr-dashed-fixed {
    border: 0;
    padding-top: 1px;
    background: repeating-linear-gradient(to right, #969aa1 0px, #a2a9b6 4px, transparent 0px, transparent 10px);
}

.spin-content {
  border: 1px solid #91ff91;
  background-color: #e6ffe9;
  border-radius: 10px;
  padding: 30px;
}
.steps-content {
  margin-top: 0px;
  padding:10px;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  border-radius: 8px;
  text-align: center;
  padding-top: 10px;
}

.timelines {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
}
.timeline-title {
	font-size: 1.2em;
	font-weight: bold;
}
.timeline-item {
	margin-bottom: 1.5em;
}

.timeline-items {
	margin: 0.8em;
	padding: 0.006em 0 0.5em 1.2em;
	border-left: 1px dotted #9e9c9c;
}
.timeline-item {
	margin-top: -1em;
}
.timeline-item time {
	display: block;
	float: left;
	background-color: #ffebeb;
	padding: 0.2em 0.4em;
}
.timeline-item > * {
	margin: 1em 0;
}
.timeline-item .location {
	clear: both;
	font-size: 0.9em;
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #302f2f;
}

#style-3{
    text-align: left;
} 

</style>

