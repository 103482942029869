<template>
  <div class="container-fluid">
    <div class="row text-left">
      <div class="col">          
          <a-tabs default-active-key="1" :size="'small'">
            <a-tab-pane key="1">
              <span slot="tab">
                <a-icon type="mail" :style="{ fontSize: '20px' }" />
                訊息
              </span>
                
                <div>
                  <b-card no-body>
                    <b-tabs pills card vertical>
                      <b-tab active>
                        <template #title>
                          <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
                          <span v-text="' 建立訊息'" />
                        </template>
                        <BlockObject @update="update" ref="con1" /> 
                      </b-tab>
                      <b-tab title="Tab 2">
                        <template #title>
                          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                          <span v-text="' 調整訊息'" />
                        </template>   
                        <BlockList @historyls="historyls" ref="con2" />                                             
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>

            </a-tab-pane>
            <a-tab-pane key="2">
              <span slot="tab">
                <a-icon type="history" :style="{ fontSize: '20px' }" />
                歷史紀錄
              </span>
              <BlockHistory ref="con3" /> 
            </a-tab-pane>            
          </a-tabs>      
      </div>
    </div>
  </div>        
</template>
<script>
import { mapState } from "vuex";
import BlockObject from "../line/control/BlockObject.vue" 
import BlockList from "../line/control/BlockList.vue"   
import BlockHistory from "../line/control/BlockHistory.vue"


export default {
  name: 'Line_control',   
  components: {
    BlockObject,
    BlockList,
    BlockHistory,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {

  },
  methods: {

    update(){
      this.$refs["con2"].reload()
    },

    historyls(){
      this.$refs["con3"].reload()
    }
  },
};
</script>
<style scoped>
.menu{
  padding:0 15px 0 0;
}
.showdiv{
  padding:20px 0 0 0;  
  border-width:1px;
  border-bottom-style:dotted;
  border-left-style:dotted;
  border-right-style:dotted;
  border-color:#acabaa;
  border-radius: 0 0 10px 10px;
}
</style>