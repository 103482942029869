<template>
  <div class="overflow-hidden">
    <a-spin :spinning="spinnings" tip="資料載中...">
    <div class="d-flex flex-column min-vh-100">
      <b-alert
        v-model="visible"
        class="position-fixed fixed-top m-0 rounded-0"
        style="z-index: 2000;"
        variant="danger"
        dismissible
      >
        <span v-html="answer" />
      </b-alert>
      <ul>
        <li>
          <div id="itemlist">          
            <a-calendar
              @select="onSelect"
              @panelChange="getChange"              
              :defaultValue="currentTime"
              :locale="locale"
              :style="{ height:'49.8rem' ,border: '1px solid #d9d9d9', borderRadius: '4px' }"
              :fullscreen="true"
              class="overflow-hidden"
            >
            <div slot="dateCellRender" slot-scope="value" class="events" style="overflow-x: hidden;overflow-y:hidden;">
                <div v-for="item  in getListData(value)" :key="item.id"  >
                  <div class="notes" :style="{'background-image': item.type==='Y'?'linear-gradient(to bottom, #c5ddf8, #3386e7)':'linear-gradient(to bottom, #dbdedb, #aeb1ae)','cursor':'pointer'}">   
                    <b-icon :icon=" item.type==='Y'? 'check2-circle':'bookmark-x'" style="margin-top:1.8rem;color:aliceblue;" font-scale="2" ></b-icon>                           
                  </div>
                </div>
            </div>  
              <template slot="monthCellRender" slot-scope="value">
                <div v-if="getMonthData(value)" class="notes-month">
                  <section>{{ getMonthData(value) }}</section>
                  <span>Backlog number</span>
                </div>
              </template>
            </a-calendar>
          </div>
        </li>
      </ul>

    </div>
    <b-modal
      ref="my-act"
      centered
      @hide="onHide"
      id="modalQrCode"
      title="我的活動資訊"
      hide-footer
    >
      <div class="text-center">
        <div v-if="list">
          <div
            class="post-preview"
            v-for="(item, $index) in information"
            :key="$index"
            style="margin:0px 0px 5px 0px;"
          >
            <div class="cards" :style="{'background-color': $index%2===0?'#549bed':'#ec8952'}">
              <img :src="item.url" alt="dog" />
              <div class="cards-details">
                <span v-text="item.actitle" class="fontstyle" />
                <br />
                <span v-text="'開始時間 : '+ item.sdate" class="fontstyle" />
                <br />
                <span v-text="'結束時間 : '+ item.edate" class="fontstyle" />
                <br />
                <b-button-group size="sm">
                  <b-button
                    class="fontstyle"
                    variant="primary"
                    v-if="( fdateformat(item.edate)>fdateformat(currentTime)) || item.cfstate=='F'?true:false"
                    @click="toGet(item)"
                  >
                    &ensp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="currentColor"
                      class="bi bi-person-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                      />
                    </svg>&ensp;
                  </b-button>
                </b-button-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </a-spin>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { actService } from "@/_services";
import * as moment from "moment/moment";
import logoImg from "@/assets/lineicon.svg";
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.findmyactbyDate(params);
};


export default {
  name: "Mviewper",
  components: {
  },
  data() {
    var my_date = new Date();
    var FDate = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
    var LDate = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);    
    return {
      currentTime: moment(),
      spinnings: false,  
      arryitems: [],
      information: [],
      lists: [],
      logoPath: logoImg,
      textValue: "",
      list: true,
      visible: false,
      destroyed: false, 
      loading: false,    
      showScanConfirmation: false,          
      camera: 'auto',  
      tipMess:'',
      answer: "",
      putObj:{},
      firstDate:FDate,
      lastDate:LDate,
      getOid:null,      
      fid: "",
      aid: "",
      locale:{
        lang:{
          month:'月',
          year:'年',
        }
      },       
    };
  },
  mounted(){
      this.reload()
  }, 
  computed: {
    ...mapState("account", ["user"]), 
    ...mapState("makeorg", ["defaultval"]) 
  }, 
  methods: {
    catImg(imgs) {
      return require(`../../../assets/${imgs}`);
    },    
    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD HH:mm");
    },
    getdate(val) {
      return moment(val).format("YYYY-MM-DD");
    },

    getListData(value) {
      let listData = [];
      if (this.arryitems !== null) {
        this.arryitems.forEach(item => {
          if (
            moment(item.sdate).format("YYYY-MM-DD") ==
            value.format("YYYY-MM-DD")
          ) {
            listData.push({
              type:
                moment(item.sdate).format("YYYY-MM-DD") >=
                this.currentTime.format("YYYY-MM-DD")
                  ? "Y"
                  : "N",
              id: item.id,
              fid: item.id,
              orgid: item.orgid,
              url: item.url,
              actitle: item.actitle,
              sdate: item.sdate,
              edate: item.edate,
              state: item.states
            });
          }
        });
        return listData || [];
      }
      return listData || [];
    },


    onSelect(e) {
      this.information = this.arryitems.filter(
        rs =>
          moment(rs.sdate).format("YYYY-MM-DD") ==
          moment(e).format("YYYY-MM-DD")
      );
      if (this.information.length > 0) {
        this.list = true;
        this.$refs["my-act"].show();
      } else {
        this.visible = true;
        this.answer = "無資料!";
        setTimeout(() => {
          this.answer = "";
          this.visible = false;
        }, 1400);        
      }
    },

    getMonthData(value) {
      if (value.month() === 8) {
        return 1394;
      }
    },

    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    reload() {
      this.fetch({ id: this.defaultval.id,firstDate:moment(this.firstDate).format("YYYY-MM"),lastDate:moment(this.lastDate).format("YYYY-MM") });
    },

    getChange(e){
      var my_date = new Date(moment(e).format("YYYY-MM-DD"));
      this.firstDate = new Date(my_date.getFullYear(), my_date.getMonth(), 1);
      //console.log(moment(this.firstDate).format("YYYY-MM-DD"));
      this.lastDate = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
      //console.log(moment(this.lastDate).format("YYYY-MM-DD"));
      this.reload()
    },    

    toGet(va1){
      this.$emit('toGet',va1)
      this.$refs["my-act"].hide();
    },
    

    fetch(params = {}) {
      this.spinnings=true  
      this.loading = true;
      queryData({
        //results: 4,
        ...params //...解壓縮出來
      }).then(({ data }) => {
        this.arryitems = data;
        this.spinnings=false
      });
    }
  }
};
</script>
<style scoped>
#itemlist {
  margin: 0px auto;
  padding: 0px;
  max-width: 1024px;
  text-align: left;
}

img {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  clip-path: polygon(-4px -4px, 100.4% -3px, 99.2% 102.57%, -0.8% 102.97%);
}

.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  width: min(98%, 500px);
  margin: 0 auto;
  box-shadow: 0 20px 34px hsla(20deg, 1%, 52%, 0.29);
  overflow: hidden;
}

.cards-details {
  text-align: left;
  position: relative;
  padding: 0 0.05rem 0 0.5rem;
}

.applyfooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  color: #f0f8f8;
  text-align: center;
  z-index: 7;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.lineHeader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 7;
  opacity: 1;
  width: 100%;
  height: 2.3rem;
  padding: 0.2rem;
  background-color: #3b1b75;
  color: #f0f8f8;
  text-align: center;
}

.fontstyle {
  font-size: 0.8rem;
  color: #faf8f8;
}

.notes{
  position: absolute;
  border-radius: 15px;
  top: 25%;
  left: 0;
  width: 80%;
  height: 70%;
  text-align: center; /* 文本水平居中 */
  vertical-align: middle; /* 文本垂直居中 */  
  cursor:pointer;
  }

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(152, 167, 189, 0.3);
  background-color: #f5f5f5;
}
#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
#style-3::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}
#style-3 {
  text-align: left;
}
</style>
<style >
  .ant-fullcalendar-content {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .ant-fullcalendar-header .ant-radio-group {
      display: none !important;
  }
</style>