<template>
  <div> 
    <b-card
          header="Flex Messages"
          header-border-variant="light"
          header-bg-variant="light"
          class="card-3"
        >
          <b-card-body
            style="width: 100%; padding: 5px 0px 5px 0px"
            class="overflow-auto"
          >
        
            <b-card-text>
              <div class="text-left" style="padding: 5px 50px 5px 50px">
                <a-alert v-if="errors!==''" :message="errors" type="info" show-icon banner />
                <b-form @submit="onSubmit">
                <p class="h6">
                  <b-icon-backspace-reverse></b-icon-backspace-reverse> Flex Message 圖片 <span v-text="'(請上傳 600 X 390 pixel且小於3MB)'" style="font-size: 0.8rem;color:brown;" /> 
                </p>
                <hr
                    style="
                      height: 1px;
                      border-width: 0;
                      color: #3d7878;
                      background-color: #3d7878;
                    "
                  />                  
              <div class="text-center">
                <a-upload
                  
                  name="imgfile"
                  method="post"
                  :show-upload-list="false"
                  :headers="headers"
                  :action="pUploadurl"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                >
                <img
                class="bimg"
                :src="xurl !== '' ? xurl : catImg('myinfor.png')"
                 />                
                </a-upload>
              </div>
                 <div style="padding:1rem;" />
                  <b-form-group
                    id="input-group-1"

                    label="標題*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.title"
                      type="text"
                      placeholder="輸入標題 "
                      :state="titleState"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-1"

                    label="內容敘述*"
                    label-for="input-1"
                  >
                    <b-form-textarea
                        id="input-2"
                        v-model="form.desc"
                        type="text"
                        placeholder="輸入內容 "
                        rows="6"
                        autocomplete="off"
                        :state="descState"                        
                        required
                    ></b-form-textarea>

                  </b-form-group>  

                  <b-form-group id="input-group-2" style="width:60%" label="狀態*  " label-for="input-2">
                    <b-form-select v-model="form.states" :options="options" class="mb-3">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- 請選擇 --</b-form-select-option>
                      </template>
                    </b-form-select>                               
                  </b-form-group> 
                  
                  <b-form-group id="input-group-2" style="width:60%" label="訊息類別*" label-for="input-2">
                    <b-form-select v-model="form.types" :options="options2" class="mb-3">
                      <template #first>
                        <b-form-select-option :value="null" disabled>-- 請選擇 --</b-form-select-option>
                      </template>
                    </b-form-select>                               
                  </b-form-group>                   

                  <b-form-group v-if="form.types==='U'"
                    id="input-group-1"
                    label="訊息類別 : 超連結*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.murl"
                      type="text"
                      placeholder="輸入連結資訊 "
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>                   
                  
                  <b-form-group v-if="form.types==='T'"
                    id="input-group-1"
                    label="訊息類別 : 文字*"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="form.mtxt"
                      type="text"
                      placeholder="輸入文字資訊 "
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>     
                </b-form>
                <div style="padding: 0.7rem">
                  <a-button
                    type="primary"
                    icon="save"
                    @click="confirm"
                    :disabled="
                      titleState || descState || form.iurl ==='' || ((form.types==='U' && form.murl==='') || (form.types==='T' && form.mtxt===''))
                    "
                    :size="'large'"
                    block
                    >存檔</a-button
                  >
                </div>
              </div>
            </b-card-text>
          </b-card-body>
        </b-card>    
  </div>
</template>
<script>
import { mapState } from "vuex";
//import * as moment from "moment/moment";
import 'moment/locale/zh-tw';

export default {
  name: "Flexmsg_main",
  props: {
    pData:{
      type: String
    },
    pUploadurl:{
      type: String
    }
  },   
  data() {
    return {
      iconLoading: false,
      loading: false,
      errors:"",
      xurl: "", 
      options: [
          { value: 'Y', text: '上架' },
          { value: 'N', text: '下架' }
        ], 
      options2: [
          { value: 'U', text: '超連結' },
          { value: 'T', text: '文字訊息' }
        ],       
      form: {
        id:"",
        seq:0,
        states:"Y",
        title:"",
        desc:"",
        types:"U",
        mtxt:"",
        murl:"",
        iurl:"",
      },       
    };
  },
  watch: {
    pData(val){
          let tmp = JSON.parse(val)
          this.form= {            
            id:tmp.id,
            seq:tmp.seq,
            states:tmp.states,
            title:tmp.title,
            desc:tmp.desc,
            types:tmp.types,
            mtxt:tmp.mtxt,
            murl:tmp.murl,
            iurl:tmp.iurl,
          } 
          this.xurl = tmp.iurl
    }
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    headers(){   
        return{                    
          "X-Requested-With":null,
          "Authorization": "Bearer "+this.status.token,
        }
      },  

    titleState() {
      return this.form.title.length > 0 ? false : true;
    },

    descState() {
        return this.form.desc.length > 0 ? false: true
      },   
  },

  methods: {
    async onSubmit(event) {
      event.preventDefault();
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },

    confirm() {
      this.form.desc = this.form.desc.replace(/"/g,"");
      let tmp = JSON.stringify(this.form)
      this.reSet()
      this.$emit('confirms',tmp)
    },

    catImg(imgs) {
      return require(`../../../../assets/${imgs}`);
    },     

    async beforeUpload(file) {
      return new Promise((resolve, reject) => {
        this.errors = "";
        //this.$emit('upmessage',null)
        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        this.iconLoading = true;
        img.onload = () => {
          const isJpgOrPng =
            file.type === "image/jpeg" || file.type === "image/png";
          const isLt2M = file.size / 1024 / 1024 < 3;
          if (img.width !== 600 || img.height !== 390) {
            this.errors = "您上傳的圖片小於 600 X 390 pixel !";
            this.mtyps = "error";
            this.xurl = "";
            this.iconLoading = false;
            reject(file);
          } else if (!isJpgOrPng) {
            //this.$message.error('您的檔案必須是 JPG or PNG!');
            this.errors = "您的檔案必須是 JPG or PNG!";
            this.mtyps = "error";
            this.xurl = "";
            this.iconLoading = false;
            //alert('您的檔案必須是 JPG or PNG!')
            //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
            reject(file);
          } else if (!isLt2M) {
            //this.$message.error('您的檔案必須是小於 2MB!');
            this.errors = "您的檔案必須是小於 2MB!";
            this.mtyps = "error";
            this.xurl = "";
            this.iconLoading = false;
            //alert('您的檔案必須是小於 3MB!')
            //this.$emit('upmessage','您的檔案必須是小於 2MB!')
            reject(file);
          } else {
            //this.$refs['my-loading'].show()
            resolve(file);
          }
        };
      });
    },

    async handleChange({ file }) {
      if (file.status === "done") {
        this.form.iurl = file.response.Location
        this.xurl=this.form.iurl 
        this.iconLoading = false;
      }
    },
   

    reSet(){
      this.form= {
        id:"",
        seq:0,
        states:"Y",
        title:"",
        desc:"",
        types:"U",
        mtxt:"",
        murl:"",
        iurl:"",
      } 
      this.xurl = ""
    },

  },
  mounted() {
  },
};
</script>
<style scoped>
.bimg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

</style>