<template>
  <div class="container-fluid">       
    <a-spin tip="資料存檔中..." :spinning="spinning">
                <Addresscom @toaddress="toaddress" style="padding:0 0 10px;" />
                <a-table
                    :columns="columns"
                    :row-key="record => record.id+':'+record.utoken+':'+record.username+':'+record.address"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    :row-selection="rowSelection"
                    @change="handleTableChange"
                    bordered
                >        
                          
                  <template slot="profile" slot-scope="record">
                    <a-avatar size="large" :src="record.pri_url" icon="user" /><span v-text="' '+record.display_name"/>
                  </template>
                  <template slot="username" slot-scope="record">
                    <div>
                      <span
                        v-text="maskName(record.username)"
                      ></span>
                    </div>
                  </template>                   
                </a-table>
    </a-spin>
  </div>
</template>   
<script>
import * as moment from "moment/moment";
import {mapState} from 'vuex';
import Addresscom from "../../../components/Addresslist.vue"
import { userService } from '../../../_services'

const queryData = params => {
  return userService.findaddress(params) 
}; 

const columns = [

  {
    title: "Icon",
    dataIndex: "",
    scopedSlots: { customRender: "profile" },
    width: '30%',
  },
  {
    title: '帳號',
    dataIndex: '',
    width: '30%',    
    scopedSlots: { customRender: 'username' },    
  }, 
  {
    title: '地址',
    dataIndex: 'address',
    width: '40%',
    scopedSlots: { customRender: 'address' },    
  } 

];

export default {
  name: 'MsgObject', 
  components: {
        Addresscom,
    },   
    props:{
        obj:{
            type:Object
        },
    },         
  data() {
    return {  
      spinning: false, 
      columns, 
      address:'', 
      data: [], 
      pagination: {defaultPageSize:4}, 
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,                   
    };
  },
  computed: {  
      ...mapState('account',['status','user']), 
      
    rowSelection() {
          const { selectedRowKeys } = this;
          
          return {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            selections: [
              {
                key: 'all-data',
                text: 'Select All Data',
                onSelect: () => {
                  this.selectedRowKeys = [...Array(46).keys()]; // 0...45
                },
              },
              {
                key: 'odd',
                text: 'Select Odd Row',
                onSelect: changableRowKeys => {
                  let newSelectedRowKeys = [];
                  newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                    if (index % 2 !== 0) {
                      return false;
                    }
                    return true;
                  });
                  this.selectedRowKeys = newSelectedRowKeys;
                },
              },
              {
                key: 'even',
                text: 'Select Even Row',
                onSelect: changableRowKeys => {
                  let newSelectedRowKeys = [];
                  newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                    if (index % 2 !== 0) {
                      return true;
                    }
                    return false;
                  });
                  this.selectedRowKeys = newSelectedRowKeys;
                },
              },
            ],
            onSelection: this.onSelection,
            getCheckboxProps: record => ({
                      props: {
                        disabled: record.utoken === "", // Column configuration not to be checked
                        name: record.name,
                      },
                    }),
          };
        },      


  },
  mounted() {
    
  },
  methods: {

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.updatemem()
    },

    maskName(name) {
            const len = name.length;
            const half = Math.ceil(len / 2);
            const maskedPart = '*'.repeat(half);
            const visiblePart = name.slice(half);
            return maskedPart + visiblePart;
    },      

    updatemem(){
           this.$emit('toMember',this.selectedRowKeys)  
    },

    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    toaddress(val,city,village,town){
        this.address = val        
        this.fetch({results:4,page:1,pageSize: 4 ,address: this.address});
        this.selectedRowKeys=[]
        this.updatemem()  
        console.log(city,village,town)        
    },  
    
    changeSpinning() {
          this.spinning = !this.spinning;
        },

    handleTableChange(pagination, filters, sorter) {
        const pager = { ...this.pagination };
        pager.current = pagination.current;
        this.pagination = pager;
        this.fetch({
            results: pagination.pageSize,
            page: pagination.current,
            sortField: sorter.field,
            sortOrder: sorter.order,
            address: this.address,
            ...filters,
        });
    }, 

    fetch(params = {}) {
    this.loading = true;
    queryData({
        ...params,
    }).then(({ data }) => {
        const pagination = { ...this.pagination };
        pagination.total = data.info.totail;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination; 
    });
    },     

    catImg(imgs) {
      return require(`../../../assets/messtype/${imgs}`);
    },      

  },
  created() {    
  },  
};
</script>

<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 10px;
  padding: 30px;
}

</style>