<template>
    <div>   
        <a-spin tip="資料處理中..." :spinning="spinning">
                <a-table
                    :columns="columns"
                    :row-key="record => record.id"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    @change="handleTableChange"
                    bordered
                >
                    <p slot="expandedRowRender" slot-scope="record" style="margin: 0;">
                        <template>
                            <div class="timeline education">
                                <h2 class="timeline-title">訊息清單</h2>
                                <div class="timeline-items">
                                    <div class="timeline-item">
                                        <h6>訊息類別</h6>
                                        <div class="location">{{ convert(record.types) }}</div>
                                    </div>
                                    <div class="timeline-item">
                                        <h6>標題</h6>
                                        <div class="location">{{ record.title }}</div>
                                    </div>
                                    <div class="timeline-item">
                                        <h6>內容</h6>
                                        <div class="location">{{ record.desc }}</div>
                                    </div>
                                    <div class="timeline-item" v-if="record.types=='2' || record.types=='3'">
                                        <h6>連結標題</h6>
                                        <div class="location">{{ record.stxt }}</div>
                                    </div>    
                                    <div class="timeline-item" v-if="record.types=='2' || record.types=='3'">
                                        <h6>連結</h6>
                                        <div class="location">{{ record.surl }}</div>
                                    </div>
                                    <div class="timeline-item" v-if="record.types=='2' || record.types=='3'">
                                        <h6>圖片</h6>
                                        <div class="location">
                                            <b-avatar rounded :src="record.url" size="5rem"></b-avatar>
                                        </div>
                                    </div>                                                                                                    
                                </div>
                            </div>

                        </template>
                    </p> 
                    <template slot="states" slot-scope="states"> {{ getState(states) }}</template>                 
                    <template slot="types" slot-scope="types"> {{ convert(types) }}</template>
                    <template slot="created_at" slot-scope="created_at"> {{ fdateformat(created_at) }}</template>
                    <a slot="action" slot-scope="obj">
                        <b-button-group size="sm">
                            <b-button @click="openedit(obj)" variant="outline-success">
                                <b-icon icon="pencil" variant="muted"></b-icon>
                                更新
                            </b-button>
                            <b-button @click="deleteitme(obj)" variant="outline-danger">                                 
                                <b-icon icon="trash" variant="muted"></b-icon>
                                刪除
                                </b-button>
                            <b-button @click="sendMsg(obj,'myMsg')" variant="outline-primary">
                                <b-icon icon="chat-right-dots" variant="muted"></b-icon>
                                訊息
                            </b-button>
                            <b-button @click="sendMsg(obj,'myMsgAct')" variant="outline-warning">
                                <b-icon icon="chat-quote" variant="muted"></b-icon>
                                活動訊息
                            </b-button>                           
                        </b-button-group>                                               
                    </a>
                </a-table> 
            </a-spin>  

                <b-modal ref="myMsg" id="modal-1" size="lg" title="傳送訊息"  @hide="onHide" centered hide-footer>                     
                    <div class="text-center"> 
                        <div class="overflow-auto" style="height: 470px;width:100%;border: 1px solid #d9dad9;padding:10px;" id="style-3"> 
                        <MsgObject :obj="this.form" @toMember="toMember"  ref="msgObj" />                      
                        </div>
                        <div style="padding:5px;" />        
                        <b-button-group size="lg" >
                            <b-button @click="cancelObj()" style="width:150px;"  variant="success">取消</b-button>
                            <b-button @click="toMsg()" :disabled="selectedman.length===0" style="width:150px;" variant="danger">
                                <b-icon icon="chat-right-dots" variant="light"></b-icon>
                                傳送訊息
                            </b-button>
                        </b-button-group>   
                    </div>        
                </b-modal> 
                
                <b-modal ref="myMsgAct" id="modal-2" size="lg" title="傳送訊息"  @hide="onHide" centered hide-footer>                     
                    <div class="text-center">  
                        <a-alert
                        v-if="sendobj!==null"
                        :message="sendobj!==null? '活動名稱 : '+sendobj.actitle + `\n`+'活動時間 : '+ toformat(sendobj.sdate,sendobj.edate) + `\n`+ '訊息通告 : '+ form.title + ' | '+ form.desc:''  "
                        type="success"
                        closable
                        :after-close="handleClose"
                        />
                        <div class="overflow-auto" style="height: 470px;width:100%;border: 1px solid #d9dad9;padding:10px;" id="style-3"> 
                            <Mviewper ref="msgView" @toGet="toGet" />                      
                        </div>
                        <div style="padding:5px;" />        
                        <b-button-group size="lg" >
                            <b-button @click="cancelObj()" style="width:150px;"  variant="success">取消</b-button>
                            <b-button @click="toMsg()" :disabled="sendobj==null" style="width:150px;" variant="danger">
                                <b-icon icon="chat-right-dots" variant="light"></b-icon>
                                傳送訊息
                            </b-button>
                        </b-button-group>   
                    </div>        
                </b-modal>                

                <b-modal ref="myDelete" id="modal-1" title="刪除該筆資料" hide-header @hide="onHide" centered hide-footer>                     
                    <div class="text-center">   
                        <div v-text="'該筆資料刪除後將無法復原!'" style="font-size:18px;padding:5px;font-weight: bold;" />        
                        <b-button-group size="lg" >
                            <b-button @click="cancelObj()" style="width:150px;"  variant="success">取消</b-button>
                            <b-button @click="toDelete()" style="width:150px;" variant="danger">刪除</b-button>
                        </b-button-group>   
                    </div>        
                </b-modal>  

                <a-modal
                    :title= "'更新該筆資料'"
                    :visible="visible2"                     
                    :closable=false   
                    :centered=true 
                    :maskClosable=false
                    :okText="'刪除'"
                    :okType="'danger'"
                    :cancelText= "'取消'"
                    :bodyStyle="{padding:'0px'}"                   
                    :width="500"
                    footer=""
                    @cancel="handelCancel2"
                    >
                    <div class="overflow-auto" style="height: 570px;width:100%;border: 1px solid #d9dad9;padding:10px;" id="style-3">
                        <div class="text-center" v-if="selected==2 || selected==3" style="margin:10px;">                  
                            <a-upload
                                name="imgfile"
                                list-type="picture"
                                class="avatar-uploader"
                                method='post'
                                :show-upload-list="false"
                                :headers="headers"
                                :action="uploadurl"
                                :before-upload="beforeUpload"
                                @change="handleChange" 
                            >  
                            <a-avatar shape="square" :src="form.url!==''? form.url:catImg('myline.png')" :size="250" icon="user" />                       
                            </a-upload>
                            <div class="mt-3" style="margin:10px;"><strong>請上傳  <span v-text="'600 X 390'" /> pixel 且小於3MB</strong></div>
                        </div>                    
                        <div class="text-left">  
                        <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon /> 
                        <div class="h6 mb-2" id="spanstate" :style="{ 'background-color': formState?'#e6fff0':'#ffe6e6','border-color':formState?'#91ffcc':'#ff9191'}">
                        <span v-text="'填寫狀態 : '" />
                        <b-icon v-if="formState" icon="unlock-fill" variant="success"></b-icon>
                        <b-icon v-if="!formState" icon="lock-fill" variant="danger"></b-icon>  
                        </div>                    
                            <b-form>                       
                                <p class="h6"><b-icon-bookmark-check></b-icon-bookmark-check> 標題內容</p> 
                                <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
                                <b-form-group id="input-group-1" style="width:60%" label="標題*" label-for="input-1">
                                    <b-form-input
                                        id="input-1"
                                        v-model="form.title"
                                        type="text"
                                        placeholder="輸入標題名稱 " 
                                        :state="titleState"  
                                        autocomplete="off"                        
                                        required
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group id="input-group-2" style="width:80%" label="內容介紹*  " label-for="input-2">
                                    <b-form-textarea
                                        id="input-2"
                                        v-model="form.desc"  
                                        type="text"
                                        placeholder="請輸入內文介紹 "
                                        rows="8"
                                        autocomplete="off"
                                        :state="descState"                        
                                        required
                                    ></b-form-textarea>
                                </b-form-group>   
                                
                                <b-form-group id="input-group-2" style="width:80%" label="狀態  " label-for="input-2">
                                    <b-form-select v-model="form.states" :options="options" class="mb-3">
                                        <template #first>
                                        <b-form-select-option :value="null" disabled>-- 請選擇 --</b-form-select-option>
                                        </template>
                                    </b-form-select>                               
                                </b-form-group>    

                                <div v-if="selected==2 || selected==3">
                                    <p class="h6"><b-icon-link></b-icon-link> 連結說明</p> 
                                    <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 

                                    <b-form-group id="input-group-3" style="width:60%" label="連結說明*" label-for="input-3">
                                        <b-form-input
                                            id="input-3"
                                            v-model="form.stxt"
                                            type="text"
                                            placeholder="輸入連結說明 " 
                                            :state="stxtState"  
                                            autocomplete="off"                        
                                            required
                                        ></b-form-input>
                                    </b-form-group>                         

                                    <b-form-group id="input-group-4" style="width:80%" label="超連結* " label-for="input-4">
                                        <b-form-input
                                            id="input-4"
                                            v-model="form.surl"
                                            type="url"
                                            placeholder="輸入超連結" 
                                            autocomplete="off"
                                            :state="surlState" 
                                            required
                                        ></b-form-input>  
                                    </b-form-group>    
                                </div>
                                <div v-if="selected==4">
                                    <b-form-group id="input-group-4" style="width:80%" label="彈性樣板訊息" label-for="input-4">
                                        <b-button variant="primary" @click="openModal()">Flex Messages</b-button> 
                                    </b-form-group>
                                        <draggable
                                            :list="getDataList"
                                            :disabled="!enabled"
                                            class="list-group"
                                            ghost-class="ghost"
                                            :move="checkMove"
                                            @start="dragging = true"
                                            @end="dragging = false"
                                        >
                                        <div
                                            class="list-group-item"
                                            v-for="element in getDataList"
                                            :key="element.id"
                                            style="padding:0.2rem;border-width:0px;"
                                            >                                  
                                            <b-list-group-item  class="d-flex align-items-center" style="border-radius:20px;">
                                                <b-avatar class="mr-3" size="40px" :src="element.iurl"></b-avatar>
                                                <span class="mr-auto"><span v-text="'標題 : '+element.title" /><span v-text="' 狀態 : '+reconvert(element.states)" /></span>
                                                <b-badge variant="light"><b-button variant="primary" @click="toEdit(element)">修改</b-button></b-badge>
                                                <b-badge variant="light"><b-button variant="danger" @click="toDel(element)">刪除</b-button></b-badge>
                                            </b-list-group-item>
                                        </div>
                                    </draggable>                                                            
                                </div>
                            </b-form> 
                        </div>  
                    </div>                                      
                    <div class="text-center" style="padding:5px;">   
                        <b-button-group size="lg" >
                            <b-button @click="cancelObj()" style="width:150px;"  variant="success">取消</b-button>
                            <b-button @click="toSave()" style="width:150px;" :disabled="!formState" variant="danger">存檔</b-button>
                        </b-button-group>   
                    </div>        
                </a-modal>    
                <a-modal
                    :title= "''"
                    :visible="showTime"
                    :zIndex=10000                      
                    :closable=true   
                    :centered=true 
                    :maskClosable=false
                    :okText="'刪除'"
                    :okType="'danger'"
                    :cancelText= "'取消'"
                    :bodyStyle="{ background  :'linear-gradient(45deg,#29536e 0%,#29536e 60%,#34607c 60%,#34607c 100%)', padding:'0px'}"
                    :width="560"
                    footer=""
                    @cancel="handelCancel"
                    >
                    <div class="text-center">
                        <Flexmsg :p-uploadurl="uploadurl" :p-data="JSON.stringify(bForm)" @confirms="confirm" />        
                    </div>
                </a-modal>                              
                 
    </div>
</template>

<script>
import { lineService } from '../../../_services'
import * as moment from "moment/moment";
import MsgObject from "../../line/msg/MsgObject.vue"  
import Mviewper from "../../line/msg/Mviewper.vue"  
import {mapState} from 'vuex';
import Flexmsg from "./panel/msg.vue"
import draggable from "vuedraggable";

const queryData = params => {
  return lineService.finditems(params) 
}; 
const deletemsg = obj => {
  return lineService.deletemsg(obj) 
};

const savemsg = obj => {
  return lineService.savemsg(obj) 
};

const tomsgs = obj => {
    return lineService.tomsgs(obj) 
}


const columns = [
  {
    title: '訊息類別',
    dataIndex: 'types',
    width: '12%',    
    scopedSlots: { customRender: 'types' },    
  }, 
  {
    title: '訊息標題',
    dataIndex: 'title',
    width: '25%',
    scopedSlots: { customRender: 'title' },    
  },  
  {
    title: '狀態',
    dataIndex: 'states',
    scopedSlots: { customRender: 'states' },    
    width: '10%',
  },    
  {
    title: '建立日期',
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'created_at' },    
    width: '13%',
  }, 
  { 
    title: ' 調整 ', 
    dataIndex: '', 
    key: 'id', 
    scopedSlots: { customRender: 'action' } ,
    width: '40%',
    },   

];

export default {
    name: 'BlockList',
    components: {
        MsgObject,
        Mviewper,
        Flexmsg,
        draggable        
    },         
    data(){
        return {
            selected:'',
            mtyps:'error',               
            tid:'',
            spinning: false, 
            errors:'',
            widthsize:{width:600,height:390}, 
            pagination: {defaultPageSize:4},
            loading: false,            
            data: [],
            columns,
            selectedman:[],
            sendobj:null,
            uploadurl:process.env.VUE_APP_URL +'user/image', 
            enabled: true,
            dragging: false,    
            showTime: false, 
            visible2:false,
            bForm:{}, 
            getDataList:[],
            options: [
                { value: 'Y', text: '上架' },
                { value: 'N', text: '下架' }
            ],                     
            form: {
                id:'',
                uid:'',
                types:'',
                title : '',            
                desc : '',
                stxt : '' ,
                surl : '',
                url:'',
                states:'',
                level:'',
                selectedman:[],
                sendobj:null,
                obj:''
                },  

        }
    },
    watch: {
    /*'form.obj':(val)=>{
            var self = this
            let tmp = JSON.parse(val)
            if(tmp!==null){
                self.setItem(tmp)
            }
     }

     form: {
            deep: true,
            handler: (newValue)=>{
                let tmp = JSON.parse(newValue.obj)
                console.log(tmp);              
            },
        } */     
    
    },        
    computed:{
      ...mapState('account',['status','user']),
      ...mapState("makeorg", ["defaultval"]),
    headers(){   
            return{                    
            "X-Requested-With":null,
            "Authorization": "Bearer "+this.status.token,
            }
        },             
      titleState() {
              return this.form.title!=='' ? true : false
            },

      descState() {
        return this.form.desc!=='' ? true : false
      },          
           
      stxtState() {
          return this.form.stxt!=='' ? true : false
      },

      surlState() {
          return this.form.surl!=='' ? true : false
      },
      
      formState() {
          if(this.selected=="1" && this.form.title!=='' && this.form.desc!==''){
            return true
          }else if((this.selected=="2" || this.selected=="3") && this.form.title!=='' && this.form.desc!=='' && this.form.stxt!=='' && this.form.surl!=='' &&  this.form.url!=='' ){
            return true
          }else if(this.selected=="4" && this.form.title!=='' && this.form.desc!=='' && this.form.obj!==null){
            if(this.form.obj.length>4){
                return true 
            }
            return false
          }else{
            return false
          }
      }      

    },    
    methods :{

        convert(val){
        let rs=''
        val=="1"? rs="文字訊息":""
        val=="2"? rs="圖文訊息":""
        val=="3"? rs="圖片訊息":""
        val=="4"? rs="彈性樣板訊息":""        
        return rs
        },
        getState(val){
            return val=="Y"? "上線":"下線"
        },       
        
        handelCancel(){
            this.showTime=false
        },   
        handelCancel2(){
            this.$refs['myDelete'].hide()     
            //this.$refs['myEdit'].hide()      
            this.$refs['myMsg'].hide()  
            this.$refs['myMsgAct'].hide() 
            this.visible2=false
        }, 
        
        fdateformat(val){
            return moment(val).format("YYYY-MM-DD"); 
        }, 
        toformat(va1,va2){
            return moment(va1).format("YYYY-MM-DD HH:mm")+'~'+ moment(va2).format("HH:mm"); 
        },

        toGet(va1){
            let db = '活動名稱 : '+va1.actitle + `\n`+'活動時間 : '+ this.toformat(va1.sdate,va1.edate) + `\n`+ '訊息通告 : '+ `\n`+ '標題 : '+ this.form.title + `\n`+'內文 : '+ this.form.desc
            let tmp ={
                id: va1.id,
                fid: va1.fid,
                actitle: va1.actitle,
                desc: db, 
                sdate:va1.sdate,
                edate:va1.edate,
            };
            this.sendobj=tmp
            this.form.sendobj=tmp
        },

        handleClose() {
            this.sendobj = null;
            this.form.sendobj=null;
        },        
        
        toMember(obj){
            this.selectedman =[]
            for(var item in obj){
                let tmp = obj[item].split(':');
                this.selectedman.push({useid :tmp[0],utoken:tmp[1],username:tmp[2],address:tmp[3]})
            }
            this.form.selectedman= this.selectedman
        },
   
        onHide(evt) {
              if(evt.trigger === "backdrop"){
                  evt.preventDefault();
              }
          },

          checkMove(e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
         },           

        openedit(obj){
            this.form={
                    id:obj.id,
                    uid:obj.uid,
                    types:obj.types,
                    title : obj.title,            
                    desc : obj.desc,
                    stxt : obj.stxt ,
                    surl : obj.surl,
                    url:obj.url,
                    states: obj.states,
                    level:obj.level,
                    selectedman:[],
                    obj: JSON.stringify(obj.flexmsg),
                    },
            this.getDataList =  obj.flexmsg.sort((a,b) => a.seq - b.seq);
            this.selected = obj.types 
            if(this.selected=='2'){
                this.widthsize.width=600
                this.widthsize.height=390
            }
            if(this.selected=='3'){
                this.widthsize.width=600
                this.widthsize.height=390
            } 
            //this.$refs['myEdit'].show()
            this.visible2=true
        },

        sendMsg(obj,va1){
            this.form={
                    id:obj.id,
                    uid:obj.uid,
                    types:obj.types,
                    title : obj.title,            
                    desc : obj.desc,
                    stxt : obj.stxt ,
                    surl : obj.surl,
                    url:obj.url,
                    level:obj.level,
                    sendobj:null,
                    obj:JSON.stringify(obj.flexmsg),
                    }, 
            this.sendobj=null                      
            this.selectedman=[]
            if(va1=='myMsg'){
                this.$refs['myMsg'].show()
            }else{
                this.$refs['myMsgAct'].show()
            }

        },

        confirm(item){
            let tmp=JSON.parse(item)
            let ary  = JSON.parse(this.form.obj)
            if(tmp.id!==""){
                for(let id in  ary) {
                    if(ary[id].id===tmp.id){
                        ary[id].states= tmp.states      
                        ary[id].title= tmp.title
                        ary[id].desc= tmp.desc
                        ary[id].types=tmp.types
                        ary[id].mtxt=tmp.mtxt
                        ary[id].murl=tmp.murl
                        ary[id].iurl=tmp.iurl
                    }
                }
                this.form.obj = JSON.stringify(ary)
                this.getDataList = ary
            }else{
                tmp.id = this.uuid()
                if(ary!==null){
                    ary.push(tmp)
                    this.form.obj = JSON.stringify(ary)
                    this.getDataList = ary
                }else{
                    let aryTemp =[]
                    aryTemp.push({
                        id:tmp.id,
                        seq:0,
                        states:tmp.states,
                        title:tmp.title,
                        desc:tmp.desc,
                        types:tmp.types,
                        mtxt:tmp.mtxt,
                        murl:tmp.murl,
                        iurl:tmp.iurl,  
                    })                    
                    this.form.obj = JSON.stringify(aryTemp) 
                    this.getDataList = aryTemp
                }                            
            }
            this.showTime = false;
        },

        uuid() {
            var s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
            }
            return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
        },  
        
        toEdit(tmp){
                this.bForm={
                    id:"",
                    seq:"",
                    states:"Y",
                    title:"",
                    desc:"",
                    types:"U",
                    mtxt:"",
                    murl:"",
                    iurl:"",
                }             
                setTimeout(async() => {
                    this.bForm={
                    id:tmp.id,
                    seq:tmp.seq,
                    states:tmp.states,
                    title:tmp.title,
                    desc:tmp.desc,
                    types:tmp.types,
                    mtxt:tmp.mtxt,
                    murl:tmp.murl,
                    iurl:tmp.iurl,
                }
                }, 100);

            this.showTime=!this.showTime  
        },
        
        toDel(tmp){
            let reData = [];
            let ary  = JSON.parse(this.form.obj)
            for(let id in  ary) {
                if(ary[id].id!==tmp.id){
                    reData.push(ary[id])
                }
                }
            this.form.obj = JSON.stringify(reData)
            this.getDataList =reData
        }, 
        
        openModal(){      
            this.bForm={
                    id:"",
                    seq:"",
                    states:"Y",
                    title:"",
                    desc:"",
                    types:"U",
                    mtxt:"",
                    murl:"",
                    iurl:"",
                }
            this.showTime=!this.showTime  
        },        

        reconvert(val){
            return val==='Y'? '上線':'下線'
        },        

        toMsg(){
            this.cancelObj()
            let self = this
            this.spinning=true
            if(this.form.sendobj==null){
                    this.form.sendobj ={
                    id: '',
                    fid: '',
                    actitle: '',
                    desc:'',
                    sdate:'',
                    edate:'',
                };
            }
            if(this.form.obj!==null){
                this.form.flexmsg = JSON.parse(this.form.obj)
            }else{
                this.form.flexmsg =[]
            }
            this.form.orgid = this.defaultval.id
            tomsgs(this.form).then(({data}) =>{
                if(data.code==200){  
                    let rs='訊息傳送成功!'  
                    if(data.msg=='undone'){
                        rs='已經超過本月用量了'
                    }                    
                    this.reload()   
                    this.form.sendobj==null             
                    setTimeout(() => {
                        self.spinning = false                 
                        self.$emit("historyls")                                                 
                        self.$message.success(rs)
                    }, 2000);                    
                }
            }); 
        },

        handleTableChange(pagination, filters, sorter) {
            //console.log(pagination);
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            //console.log(this.pagination);
            this.fetch({
                results: pagination.pageSize,
                page: pagination.current,
                sortField: sorter.field,
                sortOrder: sorter.order,
                uid: this.user.data.user.id,
                ...filters,
            });
        }, 

        fetch(params = {}) {
        this.loading = true;
        queryData({
            ...params,
        }).then(({ data }) => {
            const pagination = { ...this.pagination };
            // Read total count from server
            // pagination.total = data.totalCount;
            pagination.total = data.info.totail;
            //pagination.total = 200;
            this.loading = false;
            this.data = data.results;
            this.pagination = pagination; 
        });
        },          
          
        reload(rs){
            if(rs==null){
                this.fetch({results:4,page:1,pageSize: 4 ,uid: this.user.data.user.id});
            }else{
                this.fetch(rs);
            }
        },

        deleteitme(obj){
            this.tid=obj.id
            this.$refs['myDelete'].show()
        },  
        cancelObj(){
           this.$refs['myDelete'].hide()     
           //this.$refs['myEdit'].hide()      
           this.$refs['myMsg'].hide()  
           this.$refs['myMsgAct'].hide() 
           this.visible2=false
        },
        toDelete(){
            this.cancelObj()
            let self = this
            this.spinning=true
            deletemsg(this.tid).then(({data}) =>{
                if(data.code==200){   
                    this.reload()   
                    this.tid=''               
                    setTimeout(() => {
                        self.spinning = false                  
                        self.$message.success('資料刪除成功!')
                    }, 2000);                                                
                }
            });                       

        },

        toSave(){
            this.cancelObj()
            let self = this
            this.spinning=true
            if(this.form.obj.length>0 && this.form.obj!==null){

                let ary = this.getDataList // JSON.parse(this.form.obj) 
                let i = 0
                for(let id in  ary) {
                    i = i+1
                    ary[id].seq = i
                }
                this.form.flexmsg = ary
            }
            savemsg(this.form).then(({data}) =>{
                if(data.code==200){   
                    this.reload()                                     
                    setTimeout(() => {
                        self.spinning = false                  
                        self.$message.success('資料存檔成功!')
                    }, 2000);                    
                }
            });                       

        },

        
        
        beforeUpload(file) {
                    this.errors=''
                    //this.$emit('upmessage',null)
                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                    const isLt2M = file.size / 1024 / 1024 < 3;
                    return new Promise((resolve, reject) => {
                    let img = new Image();
                    img.src = window.URL.createObjectURL(file);
                    img.onload = () => {
                        if (img.width !== this.widthsize.width || img.height !== this.widthsize.height) {
                        this.errors = "您上傳的圖片小於 "+ this.widthsize.width +" X " + this.widthsize.height +" pixel !";
                        this.mtyps = "error";
                        this.xurl = "";
                        reject(file);
                        } else if (!isJpgOrPng) {
                        //this.$message.error('您的檔案必須是 JPG or PNG!');
                        this.errors = '您的檔案必須是 JPG or PNG!'
                        this.mtyps = "error"
                        //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
                        reject(file);
                        } else if(!isLt2M) {
                        //this.$message.error('您的檔案必須是小於 2MB!');
                        this.errors = '您的檔案必須是小於 2MB!'
                        this.mtyps = "error"
                        //this.$emit('upmessage','您的檔案必須是小於 2MB!')
                        reject(file);
                        }else {             
                        resolve(file);
                        }
                    }
                });
            },

        async handleChange({file}) {                 
                if(file.status ==='done'){
                    this.form.url = file.response.Location                 
                }
        },        

    }, 
    mounted() {
        this.reload()
    },  

}
</script>
<style scoped>
.card-body{
    padding:5px 0px 5px 0px;
}

.card-3 {
    padding:20px;
    border-radius: 6px;
    text-align: left;
    border-width:0px;
    margin: 25px auto;
    border-style:solid;
    border-color:#e0dedc;
	transition: all .3s ease;
}

.ddic {
  border-radius: 10px;
  box-shadow: 1px 1px 15px -5px rgb(206, 204, 204);
  transition: all 0.3s ease;
}

.hr-dashed-fixed {
    border: 0;
    padding-top: 1px;
    background: repeating-linear-gradient(to right, #969aa1 0px, #a2a9b6 4px, transparent 0px, transparent 10px);
}

.spin-content {
  border: 1px solid #91ff91;
  background-color: #e6ffe9;
  border-radius: 10px;
  padding: 30px;
}
.steps-content {
  margin-top: 0px;
  padding:10px;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  border-radius: 8px;
  text-align: center;
  padding-top: 10px;
}

.timelines {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-start;
}
.timeline-title {
	font-size: 1.2em;
	font-weight: bold;
}
.timeline-item {
	margin-bottom: 1.5em;
}

.timeline-items {
	margin: 0.8em;
	padding: 0.006em 0 0.5em 1.2em;
	border-left: 1px dotted #9e9c9c;
}
.timeline-item {
	margin-top: -1em;
}
.timeline-item time {
	display: block;
	float: left;
	background-color: #ffebeb;
	padding: 0.2em 0.4em;
}
.timeline-item > * {
	margin: 1em 0;
}
.timeline-item .location {
	clear: both;
	font-size: 0.9em;
}


#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #302f2f;
}

#style-3{
    text-align: left;
} 

</style>
<style>
    .ant-alert {
        text-align: left;
        white-space: pre-wrap!important;
    }
</style>

