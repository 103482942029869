<template>
  <div class="container-fluid">       
    <a-spin tip="資料存檔中..." :spinning="spinning">
      <div class="spin-content">
              <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
              </a-steps>
              <div class="steps-content">
                <div style="padding:2px;">
                  <span v-text="steps[current].content" />
                </div>
                <div v-if="current==0">
                  
                   <a-radio-group  v-model="selected" @change="onChange" button-style="solid" size="large">
                    <div v-for="item in stepsobj" :key="item.val" style="float:left;margin:8px;">
                    <a-card hoverable  style="width: 180px;padding:6px;border-radius: 10px;">
                        <img
                          slot="cover"
                          :alt="item.txt"
                          :src="catImg(item.pic)"
                        />
                        <a-card-meta title="">
                          <template slot="description">
                            <a-radio-button :value="item.val">
                              {{item.txt}}
                            </a-radio-button>
                          </template>
                        </a-card-meta>
                      </a-card>
                    </div>
                  </a-radio-group>
                  <div class="mt-3" style="margin:10px;">您選擇的為 : <strong>{{ convert(selected) }}</strong></div>
                </div>
                <div v-else-if="current==1">
                  <div class="mt-3" style="margin:30px;">
                      <div class="text-center" v-if="selected==2 || selected==3" style="margin:10px;">                  
                          <a-upload
                              name="imgfile"
                              list-type="picture"
                              class="avatar-uploader"
                              method='post'
                              :show-upload-list="false"
                              :headers="headers"
                              :action="uploadurl"
                              :before-upload="beforeUpload"
                              @change="handleChange" 
                          >  
                          <a-avatar shape="square" :src="form.url!==''? form.url:catImg('myline.png')" :size="250" icon="user" />                  
                          </a-upload>
                          <div class="mt-3" style="margin:10px;"><strong>請上傳  <span v-text="selected==2?'600 X 390':'1205 X 1849'" /> pixel 且小於3MB</strong></div>
                      </div>

                    <div class="text-left">  
                      <a-alert v-if="errors!==''" :message="errors" :type="mtyps" show-icon /> 
                      <div class="h6 mb-2" id="spanstate" :style="{ 'background-color': formState?'#e6fff0':'#ffe6e6','border-color':formState?'#91ffcc':'#ff9191'}">
                      <span v-text="'填寫狀態 : '" />
                      <b-icon v-if="formState" icon="unlock-fill" variant="success"></b-icon>
                      <b-icon v-if="!formState" icon="lock-fill" variant="danger"></b-icon>  
                      </div>                    
                        <b-form>                       
                            <p class="h6"><b-icon-bookmark-check></b-icon-bookmark-check> 標題內容</p> 
                            <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;">
                            <b-form-group id="input-group-1" style="width:60%" label="標題*" label-for="input-1">
                                <b-form-input
                                    id="input-1"
                                    v-model="form.title"
                                    type="text"
                                    placeholder="輸入標題名稱 " 
                                    :state="titleState"  
                                    autocomplete="off"                        
                                    required
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="input-group-2" style="width:80%" label="內容介紹*  " label-for="input-2">
                                <b-form-textarea
                                    id="input-2"
                                    v-model="form.desc"  
                                    type="text"
                                    placeholder="請輸入內文介紹 "
                                    rows="8"
                                    autocomplete="off"
                                    :state="descState"                        
                                    required
                                ></b-form-textarea>
                            </b-form-group> 

                            <b-form-group id="input-group-2" style="width:80%" label="狀態  " label-for="input-2">
                              <b-form-select v-model="form.states" :options="options" class="mb-3">
                                <template #first>
                                  <b-form-select-option :value="null" disabled>-- 請選擇 --</b-form-select-option>
                                </template>
                              </b-form-select>                               
                            </b-form-group>                          

                            <div v-if="selected==2 || selected==3">
                              <p class="h6"><b-icon-link></b-icon-link> 連結說明</p> 
                              <hr style="height:1px; border-width:0; color: #3D7878; background-color: #3D7878;"> 

                              <b-form-group id="input-group-3" style="width:60%" label="連結說明*" label-for="input-3">
                                  <b-form-input
                                      id="input-3"
                                      v-model="form.stxt"
                                      type="text"
                                      placeholder="輸入連結說明 " 
                                      :state="stxtState"  
                                      autocomplete="off"                        
                                      required
                                  ></b-form-input>
                              </b-form-group>                         

                              <b-form-group id="input-group-4" style="width:80%" label="超連結* " label-for="input-4">
                                  <b-form-input
                                      id="input-4"
                                      v-model="form.surl"
                                      type="url"
                                      placeholder="輸入超連結" 
                                      autocomplete="off"
                                      :state="surlState" 
                                      required
                                  ></b-form-input>  
                              </b-form-group>    
                          </div>

                          <div v-if="selected==4">
                              <b-form-group id="input-group-4" style="width:80%" label="彈性樣板訊息" label-for="input-4">
                                <b-button variant="primary" @click="openModal()">Flex Messages</b-button> 
                              </b-form-group>
                              <draggable
                                :list="form.obj"
                                :disabled="!enabled"
                                class="list-group"
                                ghost-class="ghost"
                                :move="checkMove"
                                @start="dragging = true"
                                @end="dragging = false"
                              >
                              <div
                                  class="list-group-item"
                                  v-for="element in form.obj"
                                  :key="element.id"
                                  style="padding:0.2rem;border-width:0px;"
                                >                                  
                                  <b-list-group-item  class="d-flex align-items-center" style="border-radius:20px;">
                                    <b-avatar class="mr-3" size="40px" :src="element.iurl"></b-avatar>
                                    <span class="mr-auto"><span v-text="'標題 : '+element.title" /><span v-text="' 狀態 : '+reconvert(element.states)" /></span>
                                    <b-badge variant="light"><b-button variant="primary" @click="toEdit(element)">修改</b-button></b-badge>
                                    <b-badge variant="light"><b-button variant="danger" @click="toDel(element)">刪除</b-button></b-badge>
                                  </b-list-group-item>
                                </div>
                              </draggable>                              

                          </div>
                        </b-form> 
                    </div>
                </div>                  

                </div> 

                <div v-else-if="current==2">
                    <a-result
                      :title="msg"                       
                    >
                    <template #icon>
                      <a-icon :type="iconState" theme="twoTone" />
                    </template>                                      
                    </a-result>
                </div>                                


              </div>
            
              <div class="steps-action right">
                <a-button v-if="current < steps.length - 1" :disabled="! steps[current].state" type="primary" @click="next">
                  下一步
                </a-button>
                <a-button
                  v-if="current == steps.length - 1"
                  type="primary"
                  @click="saveData"
                >
                  完成
                </a-button>
                <a-button v-if="current > 0" style="margin-left: 8px" @click="prev">
                  上一步
                </a-button>
              </div>      

      </div>
      <a-modal
        :title= "''"
        :visible="showTime"
        :closable=true   
        :centered=true 
        :maskClosable=false
        :okText="'刪除'"
        :okType="'danger'"
        :cancelText= "'取消'"
        :bodyStyle="{ background  :'linear-gradient(45deg,#29536e 0%,#29536e 60%,#34607c 60%,#34607c 100%)', padding:'0px'}"
        :width="560"
        footer=""
        @cancel="handelCancel"
        >
        <div class="text-center">
          <Flexmsg :p-uploadurl="uploadurl" :p-data="JSON.stringify(bForm)" @confirms="confirm" />        
        </div>
      </a-modal>

    </a-spin>
  </div>
</template>   
<script>
import * as moment from "moment/moment";
import {mapState} from 'vuex';
import { lineService } from '../../../_services'
import Flexmsg from "./panel/msg.vue"
import draggable from "vuedraggable";

const createmsg = obj => {
  return lineService.createmsg(obj) 
};

export default {
  name: 'BlockObject', 
  components: {
    Flexmsg,
    draggable
  },  
    props:{
        items:{
            type:Object
        },
    },         
  data() {
    return {  
      errors:'',
      mtyps:'error',      
      domList: [],  
      spinning: false, 
      showTime: false, 
      current: 0,
      selected:null,
      widthsize:{width:600,height:390}, 
      msg:'資料填寫完畢, 請執行存檔謝謝!',
      msg2:'',
      iconState:'smile', //  success
      uploadurl:process.env.VUE_APP_URL +'user/image',  
      options: [
          { value: 'Y', text: '上架' },
          { value: 'N', text: '下架' }
        ],
      bForm:{},
      form: {
           title : '',            
           desc : '',
           stxt : '' ,
           surl : '',
           url:'',
           states:'Y',
           level:'',
           obj:[]
          }, 

      stepsobj:[
        {id:1,val:"1",txt:"文字訊息",pic:"type_1.png"},
        {id:2,val:"2",txt:"圖文訊息",pic:"type_2.png"},
        {id:3,val:"3",txt:"圖片訊息",pic:"type_3.png"},
        {id:4,val:"4",txt:"FlexMsg",pic:"type_4.png"},
        ],
      steps: [  
        {
          title: '步驟一',
          content: '選擇訊息類別',
          state:false,
        },
        {
          title: '步驟二',
          content: '製作您的訊息',
          state:false,
        },
        {
          title: '完成',
          content: '執行存檔',
          state:false,
        },
      ], 
      enabled: true,
      dragging: false        
    };
  },
  computed: {  
      ...mapState('account',['status','user']),   
      ...mapState("makeorg", [ "defaultval"]),          
      headers(){   
        return{                    
          "X-Requested-With":null,
          "Authorization": "Bearer "+this.status.token,
        }
      },             
      titleState() {
              return this.form.title!=='' ? true : false
            },

      descState() {
        return this.form.desc!=='' ? true : false
      },          
           
      stxtState() {
          return this.form.stxt!=='' ? true : false
      },

      surlState() {
          return this.form.surl!=='' ? true : false
      }, 
      
      formState() {
        if(this.current===1){
          if(this.selected=="1" && this.form.title!=='' && this.form.desc!==''){
            this.changeState(this.current,true)
            return true
          }else if((this.selected=="2" || this.selected=="3") && this.form.title!=='' && this.form.desc!=='' && this.form.stxt!=='' && this.form.surl!=='' &&  this.form.url!=='' ){
            this.changeState(this.current,true)
            return true
          }else if(this.selected=="4" && this.form.title!=='' && this.form.desc!=='' && this.form.obj.length>0){
            this.changeState(this.current,true)
            return true
          }else{
            this.changeState(this.current,false)
            return false
          }
        }       
        return false
      }
  },
  mounted() {
    
  },
  methods: {
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    },
    
    changeSpinning() {
          this.spinning = !this.spinning;
    },

    confirm(item){
      let tmp=JSON.parse(item)
      if(tmp.id!==""){
        for(let id in  this.form.obj) {
          if(this.form.obj[id].id===tmp.id){
            this.form.obj[id].states= tmp.states      
            this.form.obj[id].title= tmp.title
            this.form.obj[id].desc= tmp.desc
            this.form.obj[id].types=tmp.types
            this.form.obj[id].mtxt=tmp.mtxt
            this.form.obj[id].murl=tmp.murl
            this.form.obj[id].iurl=tmp.iurl
          }
        }
      }else{
        tmp.id = this.uuid()
        this.form.obj.push(tmp)
      }

      this.showTime = false;
    },
    openModal(){      
      this.bForm={
            id:"",
            seq:"",
            states:"Y",
            title:"",
            desc:"",
            types:"U",
            mtxt:"",
            murl:"",
            iurl:"",
          }
      this.showTime=!this.showTime  
    },
    uuid() {
      var s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }, 
    reconvert(val){
      return val==='Y'? '上線':'下線'
    },

    toEdit(tmp){
      this.bForm={
            id:tmp.id,
            seq:tmp.seq,
            states:tmp.states,
            title:tmp.title,
            desc:tmp.desc,
            types:tmp.types,
            mtxt:tmp.mtxt,
            murl:tmp.murl,
            iurl:tmp.iurl,
          }
      this.showTime=!this.showTime  
    },
    toDel(tmp){
      let reData = [];
      for(let id in  this.form.obj) {
          if(this.form.obj[id].id!==tmp.id){
            reData.push(this.form.obj[id])
          }
        }
      this.form.obj =reData
    },
        
    convert(val){
      let rs=''
      val=="1"? rs="文字訊息":""
      val=="2"? rs="圖文訊息":""
      val=="3"? rs="圖片訊息":""
      val=="4"? rs="Flex Message":""      
      return rs

    },
    clearn(){
      this.selected=''
      this.form.title=''
      this.form.desc=''
      this.form.stxt=''
      this.form.surl=''
      this.form.url=''
      this.form.states=''
      this.form.obj=[]
      this.steps[0].state=false
      this.steps[1].state=false
      this.steps[2].state=false
      this.current=0
    },

    checkMove(e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },    

    onChange(e) {
      if(this.selected===e.target.value){
          this.steps[this.current].state=true
          //this.selected==='1'?this.clearn():''
      }
      if(this.selected=='2'){
        this.form.url=''
        this.errors=''
        this.widthsize.width=600
        this.widthsize.height=390
      }
      if(this.selected=='3'){
        this.form.url=''
        this.errors=''
        this.widthsize.width=1205
        this.widthsize.height=1849
      }      
    },
    
    changeState(val,state){
      this.steps[val].state=state
    },
        
    next() {
      this.current++;
    },
    prev() {
      this.current--;
    },        

    catImg(imgs) {
      return require(`../../../assets/messtype/${imgs}`);
    }, 

    saveData(){
      this.spinning= true
      let self = this
      let i = 0
      if(this.form.obj.length>0){
        for(let id in  this.form.obj) {
          i = i+1
          this.form.obj[id].seq = i
        }
      }
      let rs = {
        uid : this.user.data.user.id,
        types:this.selected,
        title:this.form.title,
        desc:this.form.desc,
        stxt:this.form.stxt,
        surl:this.form.surl,
        url:this.form.url,  
        states: this.form.states,
        level:this.defaultval.type,
        flexmsg:this.form.obj,     
      }
      createmsg(rs).then(({data}) =>{
          if(data.code==201){
            this.msg='資料存檔成功, 謝謝!',
            this.iconState='success'                        
            setTimeout(() => {
              self.spinning = false  
                self.msg='資料填寫完畢, 請執行存檔謝謝!'        
                self.iconState='smile'
                self.clearn()
                self.$message.success('資料存檔成功, 謝謝!')
                self.$emit("update")
            }, 2000);            

          }
      });   
    },
    
    beforeUpload(file) {
            this.errors=''
            //this.$emit('upmessage',null)
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 3;
            return new Promise((resolve, reject) => {
              let img = new Image();
              img.src = window.URL.createObjectURL(file);
              img.onload = () => {   
                if (img.width !== this.widthsize.width || img.height !== this.widthsize.height) {
                  this.errors = "您上傳的圖片小於 "+ this.widthsize.width +" X " + this.widthsize.height +" pixel !";
                  this.mtyps = "error";
                  this.xurl = "";
                  reject(file);
                } else if (!isJpgOrPng) {
                  //this.$message.error('您的檔案必須是 JPG or PNG!');
                  this.errors = '您的檔案必須是 JPG or PNG!'
                  this.mtyps = "error"
                  //this.$emit('upmessage','您的檔案必須是 JPG or PNG!')
                reject(file);
                } else if(!isLt2M) {
                  //this.$message.error('您的檔案必須是小於 2MB!');
                  this.errors = '您的檔案必須是小於 2MB!'
                  this.mtyps = "error"
                  //this.$emit('upmessage','您的檔案必須是小於 2MB!')
                reject(file);
                }else {             
                resolve(file);
                }
              }
          });
    },

    async handleChange({file}) {                 
            if(file.status ==='done'){
                this.form.url = file.response.Location                 
            }
    }, 
    
    handelCancel(){
      this.showTime=false
    },     

  },
  created() {    
  },  
};
</script>

<style scoped>
.spin-content {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  border-radius: 10px;
  padding: 30px;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #dddcdc;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  border-radius: 8px;
  text-align: center;
  padding-top: 10px;
}

.steps-action {
  margin-top: 24px;
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(145, 143, 143, 0.19), 0 6px 6px rgba(202, 198, 198, 0.23);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#spanstate {
  border: 1px solid;  
  border-radius: 6px;
  padding: 3px;
  text-align: center;
  max-width:110px;
}

</style>